import * as React from "react";
import { MdFileDownload } from "react-icons/md";
import { connect } from "react-redux";
import "./table.scss";
// import { ITableComponentProps, ITableComponentState } from "./Table.types";
import * as testActionCreators from "../../redux/test/test.actions";
import { bindActionCreators } from "redux";
import axios from "axios";
import * as moment from 'moment';
import {errors} from "../../utilities/errors";
import PdfContainer from '../../utilities/PDFContainer';
import { Results } from "../../modules/results";
import { base_url } from '../../config/constants';

class TableComponent extends React.Component<any> {
  state = {
    tableData: [],
    testId:  null,
    isLoading: true,
    errors: '',
    startTestErrors: '',
    getPdfData: false,
  };

  componentDidMount() {
    this.setState({
      errors: '',
      startTestErrors: '',
      getPdfData: false
    });
  }

  async componentDidUpdate(prevProps) {
    try {
      if (prevProps.cardSelected !== this.props.cardSelected) {
        this.setState({
          isLoading: true,
          errors: '',
          startTestErrors: '',
          getPdfData: false
        });

        const tableDataJson = await axios.post(
          `${base_url}/api/Client/GetTestAPIDetails`,
          {
            AuthToken: this.props.user.authToken,
            ExamId: this.props.examId,
            UserId : this.props.user.uid,
          }
        );
        
        
        this.setState({
          tableData: tableDataJson.data.Data,
          isLoading: false,
          testId :tableDataJson.data.TestId,
        });
      }
    } catch (error) {
        if (error.response) {
            const responseStatus = error.response.data.ResponseStatus;
            const errorMessage: any = errors.testAPIDetails[responseStatus].msg;
            this.setState({
                errors: <p style={{ color: "red" }}>{errorMessage}</p>,
            });
        }
    }
  }

  startExam = async (test, resetData) => {
    this.setState({startTestErrors: ''});
    try {
      if (resetData) {
        this.props.resetTestData();
      }

      const testApiResponse = await axios.post(
        `${base_url}/api/Client/GetTestStartPageDetails`,
        {
          AuthToken: this.props.user.authToken,
          ExamId: this.props.examId,
          TestId: test.Id,
        }
      );

      const testData = testApiResponse.data.Data[0];

      if (testData) {
        const {
          history: { push },
        } = this.props;

        // set loader to true when updating redux

        this.props.setCustomLoaderState(true);

        this.props.addTestDetails(
          test.Id,
          this.props.examId,
          test.testName,
          testData.createdDate,
          testData.noOfUsersTaken,
          testData.noOfQuestions,
          test.testDateTime,
          testData.instructions,
          0,
          this.props.cardSelected
        );
        setTimeout(() => push("/exam"), 2000);
      }
    } catch (error) {
        if (error.response) {
            const responseStatus = error.response.data.ResponseStatus;
            const errorMessage: any = errors.startPageData[responseStatus].msg;
            this.setState({
                startTestErrors: <p style={{ color: "red" }}>{errorMessage}</p>,
            });
        }
    }
  };

  resumeExam = async (test: any) => {
    try {
      if (test.Id !== this.props.currentTestId) {
          this.props.resetTestData();
          const testApiResponse = await axios.post(
              `${base_url}/api/Client/ResumeTestQuestions`,
              {
                  AuthToken: this.props.user.authToken,
                  UserId: this.props.user.uid,
                  ExamId: this.props.examId,
                  TestId: test.Id,
              }
          );
          const testData = testApiResponse.data.Data;
          this.props.resumeTest(testData, test.freeTrial);
      } else if(test.Id === this.props.currentTestId && test.freeTrial) {
          const totalTimeTaken = Object.keys(this.props.performanceData).reduce((acc, key) => {
             acc += this.props.performanceData[key].reduce((acc2, curr2) => {
                acc2 += curr2 ?? 0
                return acc2;
             }, 0);
             return acc;
          }, 0);

          this.props.updateTotalTimeTaken(totalTimeTaken);
      }

      this.setState({startTestErrors: ''});
      this.startExam(test, false);
    } catch (error) {
        if (error.response) {
            const responseStatus = error.response.data.ResponseStatus;
            const errorMessage: any = errors.startPageData[responseStatus].msg;
            this.setState({
                startTestErrors: <p style={{ color: "red" }}>{errorMessage}</p>,
            });
        }
    }
  };

  startFreeTrial = (test: any) => {
     this.props.updateFreeTrial();
     this.props.setCustomLoaderState(true);
     this.startExam(test, true)
     setTimeout(() => this.props.history.push("/exam"), 2000);
  }

  showLoader = (isFetching) => {
    this.setState({
      isLoading: isFetching,
    });
  }

  getPdfDetails = (test) => {
    this.props.addPdfTestDetails(
      test.Id,
      this.props.examId,
      test.testName,
      this.props.cardSelected
    );

    this.setState({
      getPdfData: true,
      testId: test.Id
    });
    setTimeout(() => {
      this.setState({
        getPdfData: false,
      });
    }, 10000);
  }

  viewResult = (test) => {
      this.props.addTestDetails(
          test.Id,
          this.props.examId,
          test.testName,
          null,
          null,
          null,
          null,
          null,
          0,
          this.props.cardSelected
      );
      this.props.history.push("/result")
  }

  enrollTest = (test) => {
      this.props.addTestDetails(
          test.Id,
          this.props.examId,
          test.testName,
          null,
          null,
          null,
          null,
          null,
          parseInt(test.ExamFee ?? 0),
          this.props.cardSelected
      );
      this.props.history.push("/testEnroll")
  }

  decideButton = (test): JSX.Element => {
    console.log(test);
    if (test.enrolled || test.freeTrial) {
      if (test.completed) {
        return (
          <button
            type="button"
            className="btn btn-sm btn-primary btn-block table-button view-button"
            onClick={() => this.viewResult(test)}
          >
            View
          </button>
        );
      } else if (test.started && !test.completed) {
        return (
          <button
            type="button"
            className="btn btn-sm btn-success btn-block table-button start-button"
            onClick={async (event) => {
              event.preventDefault();

              this.resumeExam(test);
            }}
          >
            Resume
          </button>
        );
      } else if (test.freeTrial) {
        return (
          <button
            type="button"
            className="btn btn-sm btn-success btn-block table-button start-button"
            onClick={(event) => {
              event.preventDefault();
              this.startFreeTrial(test);
            }}
          >
            Free Trial
          </button>
        );
      } else {
        return (
          <button
            type="button"
            className="btn btn-sm btn-success btn-block table-button start-button"
            onClick={async (event) => {
              event.preventDefault();

              this.startExam(test, true);
            }}
          >
            Start
          </button>
        );
      }
    } else {
      return (
        <div>
        <button
          type="button"
          className="btn btn-sm btn-primary btn-block table-button enroll-button"
          onClick={async (event) => {
            event.preventDefault();

            this.enrollTest(test);
          }}
        >
          Enroll
        </button>
        
        </div>
      );
    }
  };

  render(): JSX.Element {
    if(!this.props.cardSelected) {
        return null;
    } else if(this.state.errors) {
        return (<div><strong>{this.state.errors}</strong></div>)
    } else if (this.state.isLoading) {
      return (
        <div className="spinner-table">
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-success" role="status">
            <span className="sr-only">Loading...</span>
          </div>

          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-success" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return (
        <>
            {this.state.startTestErrors}
            <table className="table table-striped test-detail-table">
                <thead>
                <tr>
                    <th scope="col">Test Name</th>
                    <th scope="col">Mockup Date</th>
                    <th scope="col">Mockup Time</th>
                    <th scope="col">Rank Acquired</th>
                    <th scope="col">Score Card</th>
                    <th scope="col">Users Enrolled</th>
                    <th scope="col"> </th>
                    {/*<th scope="col">Price</th>*/}
                    {/* <th scope="col"> </th> */}

                </tr>
                </thead>
                <tbody>
                {this.state.tableData.map((test) => {
                    const date = moment(test.testDateTime).format('DD MMM YYYY');
                    const time = moment(test.testDateTime).format('hh:mm A');

                    return (
                        <tr key={test.testName}>
                            <td className="test-name-text">{test.testName}</td>
                            <td className="mockup-date-text">{`${!test.freeTrial ? date : '-'}`}</td>
                            <td className="mockup-time-text">{!test.freeTrial ? time : '-'}</td>
                            <td className="rank-acquired-text">
                                {test.rankAcquired && !test.freeTrial ? test.rankAcquired : "-"}
                            </td>
                            <td className="score-card-text">
                                {test.scoreCard ? test.scoreCard : "-"}
                            </td>
                            <td className="users-enrolled-text">{!test.freeTrial ? test.usersEnrolled : '-'}</td>
                            <td className="td-button" style={{flexDirection:"column"}}>{this.decideButton(test)}</td>
                            <td className="price">₹{test.ExamFee}</td> 
                            <td className="td-button"></td>

                            <td className="td-file-download">
                                {test.completed ? (
                                   <>
                                    {this.state.getPdfData && test.Id === this.state.testId && (
                                      <div className="spinner-border text-dark" role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    )}
                                    <MdFileDownload
                                        className="file-download-icon"
                                        onClick={() => this.getPdfDetails(test)}
                                    />
                                    </>
                                ) : null}
                            </td>

                        </tr>
                    );
                })}
                </tbody>
            </table>
            {this.state.getPdfData && (
                <div style={{ marginTop: '2em', position: "absolute", left: "-10000px", top: 0}}>
                  <PdfContainer>
                    <Results isPdfView={true} />
                  </PdfContainer>
                </div>
            )}
        </>
      );
    }
  }
}

const Table = connect(
  (state: any) => {
    return {
      currentTestId:state.test.currentTestId,
      currentExamId:state.test.currentExamId,
      testName: state.test.testName,
      examName: state.test.examName,
      createdDate: state.test.createdDate,
      noOfUsersTaken: state.test.noOfUsersTaken,
      noOfQuestions: state.test.noOfQuestions,
      timerInitialTime: state.test.timerInitialTime,
      instructions: state.test.instructions,
      performanceData: state.test.performanceData,
      user: state.users.user,
    };
  },
  (dispatch) => bindActionCreators(testActionCreators, dispatch)
)(TableComponent);

export { Table };
